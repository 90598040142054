var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      ref: "form",
      staticClass: "d-flex justify-content-center",
      attrs: {
        id: "form-availability",
        validated: _vm.validated,
        novalidate: ""
      }
    },
    [
      _c("ChargingPointAvailabilityEdit", {
        attrs: { availability: _vm.chargingPoint.availability }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }