var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("stripe-element", {
    ref: "element",
    attrs: {
      type: "iban",
      stripe: _vm.stripe,
      value: _vm.value,
      options: _vm.options
    },
    on: {
      blur: function($event) {
        return _vm.$emit("blur")
      },
      focus: function($event) {
        return _vm.$emit("focus")
      },
      change: function($event) {
        return _vm.$emit("change", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }