<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-row>
      <b-col class="button">
        <ImagePicker
          id="fuIdCard"
          v-model="documents.idCard"
          caption="Pièce d'identité"
          icon="id-card"
        />
      </b-col>
    </b-form-row>
    <b-form-row class="mt-5">
      <b-col class="button">
        <ImagePicker
          id="fuPoa"
          v-model="documents.proofOfAddress"
          caption="Justificatif de domicile"
          icon="file-alt"
        />
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import ImagePicker from '@/components/Registration/ImagePicker.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step8',
  components: { ImagePicker },
  mixins: [StepMixin],
  props: {
    documents: { type: Object, required: true }
  }
}
</script>

<style lang="scss">
#register-driver,
#register-provider {
  .form-row {
    .col.button {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
