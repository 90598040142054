var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "decorated" },
    [
      _c("HeaderNavigation", {
        attrs: { title: "ajouter un point de recharge" }
      }),
      _c(
        "b-container",
        { staticClass: "main-container", attrs: { id: "register-provider" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("h1", [_vm._v(_vm._s(_vm.currentCaption))]),
                  _c("Stepper", {
                    attrs: { steps: _vm.steps.length, step: _vm.step }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  !_vm.lastStep
                    ? _c("ChargingPointYellow")
                    : _c("IdFilesYellow"),
                  _c(
                    "p",
                    {
                      staticClass: "info",
                      attrs: { "v-show": _vm.currentInfo }
                    },
                    [_vm._v(_vm._s(_vm.currentInfo))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _vm.step == 2
                    ? _c("Step2", {
                        ref: "step2",
                        model: {
                          value: _vm.addressLocation,
                          callback: function($$v) {
                            _vm.addressLocation = $$v
                          },
                          expression: "addressLocation"
                        }
                      })
                    : _vm.step == 3
                    ? _c("Step3", {
                        ref: "step3",
                        attrs: {
                          chargingPoint: _vm.chargingPoint,
                          chargingPointTypes: _vm.refs.chargingPointTypes
                        }
                      })
                    : _vm.step == 4
                    ? _c("Step4", {
                        ref: "step4",
                        attrs: { chargingPoint: _vm.chargingPoint }
                      })
                    : _vm.step == 5
                    ? _c("Step5", {
                        ref: "step5",
                        attrs: {
                          chargingPoint: _vm.chargingPoint,
                          parkabilities: _vm.refs.parkabilities,
                          accessibilities: _vm.refs.accessibilities
                        }
                      })
                    : _vm.step == 6
                    ? _c("Step6", {
                        ref: "step6",
                        attrs: { chargingPoint: _vm.chargingPoint }
                      })
                    : _vm.step == 7
                    ? _c("Step7", {
                        ref: "step7",
                        attrs: { bankTransfer: _vm.bankTransfer }
                      })
                    : _vm.step == 8
                    ? _c("Step8", {
                        ref: "step8",
                        attrs: { documents: _vm.documents }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-3" },
            [
              _c(
                "b-col",
                [
                  !_vm.lastStep
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "loader",
                              rawName: "v-loader.disable",
                              value: _vm.busy,
                              expression: "busy",
                              modifiers: { disable: true }
                            }
                          ],
                          staticClass: "next",
                          attrs: { variant: "light" },
                          on: { click: _vm.nextStep }
                        },
                        [_vm._v(" SUIVANT ")]
                      )
                    : _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "loader",
                              rawName: "v-loader.disable",
                              value: _vm.registering,
                              expression: "registering",
                              modifiers: { disable: true }
                            }
                          ],
                          staticClass: "next",
                          attrs: {
                            variant: "light",
                            "router-tag": "button",
                            to: _vm.routes.REGISTRATION_FINISHED,
                            disabled: !_vm.isIdCardSet || !_vm.isPoaSet
                          }
                        },
                        [_vm._v(" TERMINER ")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }