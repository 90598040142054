<template>
  <b-form
    id="form-availability"
    ref="form"
    class="d-flex justify-content-center"
    :validated="validated"
    novalidate
  >
    <ChargingPointAvailabilityEdit :availability="chargingPoint.availability" />
  </b-form>
</template>

<script>
import ChargingPointAvailabilityEdit from '@/components/ChargingPoints/ChargingPointAvailabilityEdit.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step6',
  components: { ChargingPointAvailabilityEdit },
  mixins: [StepMixin],
  props: {
    chargingPoint: { type: Object, required: true }
  }
}
</script>

<style lang="scss">
// TODO: Put in scoped in sub-component
#form-availability.was-validated {
  fieldset.form-group.time-picker {
    input.form-control {
      padding-right: 0.75rem;
    }
  }
}
</style>
