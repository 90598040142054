<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-group label="NOM COMPLET">
      <b-form-input type="text" v-model="computedFullName" required />
    </b-form-group>
    <b-form-group label="ADDRESSE DU TITULAIRE">
      <InputAddress
        v-model="address"
        format="{streetNumber} {streetName}"
        @select="onSelect"
        required
      />
    </b-form-group>
    <b-form-group label="CODE POSTAL">
      <b-form-input
        type="text"
        v-model="bankTransfer.postalCode"
        pattern="\d{5}"
        required
      />
    </b-form-group>
    <b-form-group label="VILLE">
      <b-form-input type="text" v-model="bankTransfer.city" required />
    </b-form-group>
    <b-form-group label="PAYS">
      <b-form-input
        type="text"
        v-model="bankTransfer.country"
        pattern="[a-zA-Z]{2}"
        required
      />
    </b-form-group>
    <b-form-group
      label="IBAN"
      :state="isIbanValid"
      :invalid-feedback="ibanError"
    >
      <iban
        v-show="false"
        ref="iban"
        id="iban"
        :stripe="stripeKey"
        :options="options"
        @change="ibanCompleted = $event.complete"
      />
      <b-form-input
        ref="ibanInput"
        type="text"
        v-model="bankTransfer.IBAN"
        placeholder="FR12 3456 7890 1234 5678 9012 345"
        required
        @change="resetErrorMessage()"
      />
    </b-form-group>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex'
import InputAddress from '@/components/Search/InputAddress.vue'
import Iban from '@/components/Stripe/Iban.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step7',
  components: { InputAddress, Iban },
  mixins: [StepMixin],
  props: {
    bankTransfer: { type: Object, required: true }
  },
  data() {
    return {
      address: {
        address: {
          streetName: this.bankTransfer.address
        },
        position: [0, 0]
      },
      fullNameInput: undefined,
      ibanCompleted: false,
      stripeKey: process.env.VUE_APP_STRIPE_API_PublicKey,
      options: {
        supportedCountries: ['SEPA'],
        style: {
          base: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: '14px'
          }
        }
      },
      ibanError: undefined
    }
  },
  computed: {
    computedFullName: {
      get() {
        return this.fullNameInput || this.$store.state.userInfo.fullName
      },
      set(value) {
        this.fullNameInput = value
      }
    },
    isIbanValid() {
      return this.ibanError === undefined ? undefined : !this.ibanError
    }
  },
  methods: {
    ...mapActions(['createIban']),
    onSelect(evt) {
      this.bankTransfer.address =
        evt.address.streetNumber + ' ' + evt.address.streetName
      this.bankTransfer.postalCode = evt.address.postalCode
      this.bankTransfer.city = evt.address.municipality
      this.bankTransfer.country = evt.address.countryCode
    },
    isIbanCompleted() {
      return true //this.ibanCompleted
    },
    resetErrorMessage() {
      //this.$refs.ibanInput.setCustomValidity('')
      this.ibanError = undefined
    },
    setErrorMessage(msg) {
      //this.$refs.ibanInput.setCustomValidity(msg)
      this.ibanError = msg
    },
    registerIbanAsync() {
      this.setErrorMessage('')

      return this.createIban({
        api: this.$apiService,
        ibanInfo: {
          name: this.computedFullName,
          email: this.$store.state.userInfo.email,
          ...this.bankTransfer
        }
      })
        .then(() => true) // Move to next step
        .catch(err => {
          if (!(err instanceof Error)) {
            // Stripe error
            if (err.code == 'invalid_bank_account_iban') {
              this.setErrorMessage('Ce numéro IBAN est invalide')
            } else {
              this.setErrorMessage(err.message)
            }

            Promise.resolve(false) // Don't move to next step
          } else {
            console.error('Something went wrong: ', err)

            Promise.reject(err)
          }
        })
    }
  }
}
</script>
<style lang="scss">
@import '@/styles.scss';

.StripeElement {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  padding-left: 0;
  background-color: #fff;
}
</style>
