var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validated: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Accès au stationnement" } },
        [
          _c(
            "b-form-select",
            {
              attrs: { required: "" },
              model: {
                value: _vm.chargingPoint.context.parkability,
                callback: function($$v) {
                  _vm.$set(_vm.chargingPoint.context, "parkability", $$v)
                },
                expression: "chargingPoint.context.parkability"
              }
            },
            _vm._l(_vm.parkabilities, function(parkability) {
              return _c(
                "option",
                {
                  key: parkability.code,
                  domProps: { value: parkability.label }
                },
                [_vm._v(_vm._s(parkability.label))]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Démarrage de la recharge" } },
        [
          _c(
            "b-form-select",
            {
              attrs: { required: "" },
              model: {
                value: _vm.chargingPoint.context.accessibility,
                callback: function($$v) {
                  _vm.$set(_vm.chargingPoint.context, "accessibility", $$v)
                },
                expression: "chargingPoint.context.accessibility"
              }
            },
            _vm._l(_vm.accessibilities, function(accessibility) {
              return _c(
                "option",
                {
                  key: accessibility.code,
                  domProps: { value: accessibility.label }
                },
                [_vm._v(_vm._s(accessibility.label))]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Détails complémentaires" } },
        [
          _c("b-form-input", {
            model: {
              value: _vm.chargingPoint.context.details,
              callback: function($$v) {
                _vm.$set(_vm.chargingPoint.context, "details", $$v)
              },
              expression: "chargingPoint.context.details"
            }
          })
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c("b-col", { staticClass: "button" }, [
            _c(
              "div",
              { staticClass: "image-picker" },
              [
                _c(
                  "file-upload",
                  {
                    ref: "fuchargingPoint",
                    staticClass:
                      "btn btn-dark add d-flex justify-content-center",
                    attrs: {
                      "input-id": "fuchargingPoint",
                      multiple: true,
                      "post-action": "/post.method",
                      "put-action": "/put.method"
                    },
                    on: {
                      "input-file": _vm.inputFile,
                      "input-filter": _vm.inputFilterchargingPoint
                    },
                    model: {
                      value: _vm.chargingPointFiles,
                      callback: function($$v) {
                        _vm.chargingPointFiles = $$v
                      },
                      expression: "chargingPointFiles"
                    }
                  },
                  [
                    _c("span", { staticClass: "caption align-self-center" }, [
                      _vm._v("AJOUTER UNE OU PLUSIEURS PHOTOS")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "chargingPoint-photos" },
              _vm._l(_vm.chargingPoint.context.photos, function(photo, idx) {
                return _c("div", { key: idx }, [
                  _c(
                    "button",
                    {
                      staticClass: "delete",
                      attrs: { variant: "light" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeImage(idx)
                        }
                      }
                    },
                    [_vm._v(" × ")]
                  ),
                  _c("img", { attrs: { src: photo.url } })
                ])
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }