<template>
  <stripe-element
    ref="element"
    type="iban"
    :stripe="stripe"
    :value="value"
    :options="options"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { StripeElement } from 'vue-stripe-elements-plus'

export default {
  props: {
    stripe: { type: [String, Object], required: true },
    value: { type: String, required: false },
    options: { type: Object, required: false }
  },
  components: { StripeElement },
  methods: {
    blur() {
      this.$refs.element.blur()
    },
    clear() {
      this.$refs.element.clear()
    },
    focus() {
      this.$refs.element.focus()
    },
    update(options) {
      this.$refs.element.update(options)
    }
  }
}
</script>

<style></style>
