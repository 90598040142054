var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validated: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { staticClass: "button" },
            [
              _c("ImagePicker", {
                attrs: {
                  id: "fuIdCard",
                  caption: "Pièce d'identité",
                  icon: "id-card"
                },
                model: {
                  value: _vm.documents.idCard,
                  callback: function($$v) {
                    _vm.$set(_vm.documents, "idCard", $$v)
                  },
                  expression: "documents.idCard"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            { staticClass: "button" },
            [
              _c("ImagePicker", {
                attrs: {
                  id: "fuPoa",
                  caption: "Justificatif de domicile",
                  icon: "file-alt"
                },
                model: {
                  value: _vm.documents.proofOfAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.documents, "proofOfAddress", $$v)
                  },
                  expression: "documents.proofOfAddress"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }