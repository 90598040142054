<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-group
      label="Prix pour 30 minutes"
      :description="priceAdviceDescription"
    >
      <b-form-input
        type="number"
        v-model="chargingPoint.price"
        min="0.5"
        step="0.1"
        required
      />
    </b-form-group>
  </b-form>
</template>

<script>
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step4',
  mixins: [StepMixin],
  props: {
    chargingPoint: { type: Object, required: true }
  },
  computed: {
    priceAdvice() {
      switch (this.chargingPoint.type) {
        case 'TE':
          return 0.5
        case 'T2':
          switch (Number(this.chargingPoint.power)) {
            case 3.7:
              return 1
            case 7.4:
              return 2
            case 11:
              return 2.5
            case 22:
              return 5
          }
      }

      return 0
    },
    priceAdviceDescription() {
      return this.priceAdvice
        ? `Tarif indicatif pour ce type de prise : ${this.priceAdvice} € / 30 min`
        : ''
    }
  },
  mounted() {
    this.chargingPoint.price = this.priceAdvice
  }
}
</script>
