<template>
  <div class="decorated">
    <HeaderNavigation title="ajouter un point de recharge" />
    <b-container id="register-provider" class="main-container">
      <b-row>
        <b-col>
          <h1>{{ currentCaption }}</h1>
          <Stepper :steps="steps.length" :step="step" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ChargingPointYellow v-if="!lastStep" />
          <IdFilesYellow v-else />
          <p :v-show="currentInfo" class="info">{{ currentInfo }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Step2 ref="step2" v-if="step == 2" v-model="addressLocation" />
          <Step3
            ref="step3"
            v-else-if="step == 3"
            :chargingPoint="chargingPoint"
            :chargingPointTypes="refs.chargingPointTypes"
          />
          <Step4
            ref="step4"
            v-else-if="step == 4"
            :chargingPoint="chargingPoint"
          />
          <Step5
            ref="step5"
            v-else-if="step == 5"
            :chargingPoint="chargingPoint"
            :parkabilities="refs.parkabilities"
            :accessibilities="refs.accessibilities"
          />
          <Step6
            ref="step6"
            v-else-if="step == 6"
            :chargingPoint="chargingPoint"
          />
          <Step7
            ref="step7"
            v-else-if="step == 7"
            :bankTransfer="bankTransfer"
          />
          <Step8 ref="step8" v-else-if="step == 8" :documents="documents" />
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-button
            v-if="!lastStep"
            v-loader.disable="busy"
            variant="light"
            class="next"
            @click="nextStep"
          >
            SUIVANT
          </b-button>
          <b-button
            v-else
            v-loader.disable="registering"
            variant="light"
            class="next"
            router-tag="button"
            :to="routes.REGISTRATION_FINISHED"
            :disabled="!isIdCardSet || !isPoaSet"
          >
            TERMINER
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Stepper from '@/components/Registration/Stepper.vue'
import Step2 from '@/components/Registration/RegisterProvider/Step2.vue'
import Step3 from '@/components/Registration/RegisterProvider/Step3.vue'
import Step4 from '@/components/Registration/RegisterProvider/Step4.vue'
import Step5 from '@/components/Registration/RegisterProvider/Step5.vue'
import Step6 from '@/components/Registration/RegisterProvider/Step6.vue'
import Step7 from '@/components/Registration/RegisterProvider/Step7.vue'
import Step8 from '@/components/Registration/RegisterProvider/Step8.vue'
import ChargingPointYellow from '@/components/svg/ChargingPointYellow.vue'
import IdFilesYellow from '@/components/svg/IdFilesYellow.vue'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import { DefaultAvailability } from '@/mixins/cp-availability.js'
import ProfileRegistrationMixin from '@/mixins/profile-registration.js'
import { Routes } from '@/router.js'

const STEPS = Object.freeze([
  {},
  {
    caption: 'Ajoutez un point de recharge pour la communauté'
  },
  {
    caption: 'Définissez votre point de recharge'
  },
  {
    caption: 'Précisez le tarif de votre service de recharge',
    info:
      // 'Le prix horaire moyen pour votre type de prise et contexte est de 5 euros de l’heure. Vous pouvez le modifier si nécessaire ci-dessous.'
      'Définissez le tarif que vous souhaitez percevoir.'
  },
  {
    caption: "Détaillez l'environnement de votre point de recharge"
  },
  {
    caption: 'Précisez la disponibilité de votre point de recharge',
    info:
      'Définissez les plages horaires de disponibilité de votre point de recharge.'
  },
  {
    caption: 'Ajoutez vos informations bancaires.',
    info: 'Vos gains vous serons versés mensuellement par un virement bancaire.'
  },
  {
    caption:
      "Ajoutez une photograhie de votre pièce d'identité et un justificatif de domicile de moins de 3 mois"
  }
])

export const RegisterProviderStepCount = STEPS.length

export default {
  name: 'register-provider',
  components: {
    HeaderNavigation,
    Stepper,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    ChargingPointYellow,
    IdFilesYellow
  },
  mixins: [ProfileRegistrationMixin],
  data() {
    return {
      step: 2,
      steps: STEPS,
      chargingPointAddress: {},
      chargingPoint: {
        address: '',
        position: {
          lon: 0,
          lat: 0
        },
        type: null,
        power: null,
        private: false,
        price: 0,
        context: {
          parkability: null,
          accessibility: null,
          details: null,
          photos: []
        },
        availability: DefaultAvailability(true)
      },
      bankTransfer: {
        fullName: '',
        address: '',
        postalCode: '',
        city: '',
        country: 'FR',
        IBAN: ''
      },
      documents: {
        idCard: {
          file: undefined,
          type: undefined,
          url: undefined,
          isValid: undefined
        },
        proofOfAddress: {
          file: undefined,
          type: undefined,
          url: undefined,
          isValid: undefined
        }
      },
      busy: false,
      registering: false,
      routes: Routes
    }
  },
  computed: {
    ...mapState(['userInfo']),
    addressLocation: {
      get() {
        return this.chargingPointAddress
      },
      set(value) {
        this.chargingPointAddress = value

        this.chargingPoint.address = value.address.freeformAddress
        this.chargingPoint.position = value.position

        this.bankTransfer.address =
          value.address.streetNumber + ' ' + value.address.streetName
        this.bankTransfer.postalCode = value.address.postalCode
        this.bankTransfer.city = value.address.municipality
        this.bankTransfer.country = value.address.countryCode
      }
    },
    refs() {
      return {
        chargingPointTypes: this.$store.getters.chargingPointTypes || [],
        parkabilities: [
          { code: 1, label: 'Libre' },
          { code: 2, label: 'Sécurisé' }
        ],
        accessibilities: [
          { code: 1, label: 'Automatique' },
          { code: 2, label: 'Par badge' }
        ]
      }
    },
    isIdCardSet() {
      return this.isDocumentSet(this.documents.idCard)
    },
    isPoaSet() {
      return this.isDocumentSet(this.documents.proofOfAddress)
    }
  },
  mounted() {
    this.bankTransfer.fullName = this.userInfo.fullName

    this.loadChargingPointTypes({ api: this.$apiService, refresh: true })
    this.loadIdCard({ api: this.$apiService }).then(
      idCard => (this.documents.idCard = idCard)
    )
  },
  async beforeRouteLeave(to, from, next) {
    if (to.name == Routes.MENU.name) next()
    else {
      if (!this.isCurrentFormValid()) {
        next(false)
      } else {
        try {
          this.registering = true
          const cp = { ...this.chargingPoint } // copy - old: JSON.parse(JSON.stringify(this.chargingPoint))
          const proms = []

          proms.push(
            this.$apiService
              .postUserChargingPointAsync(cp)
              .then(cp => {
                this.chargingPoint.id = cp.id
                const _proms = []

                for (let photo of this.chargingPoint.context.photos) {
                  _proms.push(
                    this.$apiService
                      .postChargingPointPhotoAsync(cp.id, photo.file)
                      .then(() => URL.revokeObjectURL(photo.url))
                      .catch(err =>
                        console.error(
                          'Error while uploading charging point photo: ',
                          err
                        )
                      )
                  )
                }

                _proms.push(
                  this.$apiService
                    .putChargingPointProofOfAddressAsync(
                      cp.id,
                      this.documents.proofOfAddress.file
                    )
                    .catch(err =>
                      console.log(
                        'Error while uploading proof of address: ',
                        err
                      )
                    )
                )

                return Promise.all(_proms)
              })
              .catch(err =>
                console.error('Error when posting charging point: ', err)
              )
          )

          if (this.documents.idCard.file) {
            proms.push(
              this.$apiService
                .putUserIdCardAsync(this.documents.idCard.file)
                .catch(err =>
                  console.log("Error while uploading users's ID card: ", err)
                )
            )
          }

          await Promise.all(proms).then(() => {
            this.registerProvider({
              chargingPoint: this.chargingPoint,
              documents: this.documents
            })
          })

          next()
        } catch (err) {
          console.error(
            "Uncaught error while updating provider's profile: ",
            err
          )

          next(false)
        } finally {
          this.registering = false
        }
      }
    }
  },
  methods: {
    ...mapActions(['loadChargingPointTypes', 'loadIdCard', 'registerProvider']),
    isDocumentSet(doc) {
      return !!doc.url
    },
    checkStepRules() {
      return !(
        (this.step == 5 && this.chargingPoint.context.photos.length == 0) ||
        (this.step == 7 && !this.$refs.step7.isIbanCompleted()) ||
        (this.step == 8 && (!this.isIdCardSet || !this.isPoaSet))
      )
    },
    async nextStep() {
      if (!this.lastStep && this.isCurrentFormValid()) {
        if (this.step == 7) {
          this.busy = true

          await this.$refs.step7
            .registerIbanAsync()
            .then(nextStep => {
              if (nextStep) this.step++
            })
            .catch(err => {
              console.error("Couldn't register IBAN: ", err)
            })
            .finally(() => (this.busy = false))
        } else {
          this.step++
        }

        if (this.step == 7 && this.$store.getters.isIbanSet) {
          this.step++
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#register-provider {
  height: 100%;
  text-align: center;

  h1 {
    margin: 94px auto 9px;
    height: 34px;
    font-size: 13px;
    font-weight: $font-weight-bold;
    line-height: 17px;
    text-transform: uppercase;
  }

  svg {
    margin: 6px auto 19px;
    width: 230px;
    height: 120px;
  }

  .info {
    font-size: 14px;
    font-weight: $font-weight-regular;
    text-align: left;
  }

  .btn.next {
    width: 98px;

    &.disabled {
      width: auto;
    }
  }
}

.address-list-item {
  z-index: 100;
}
</style>
