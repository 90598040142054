var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validated: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Prix pour 30 minutes",
            description: _vm.priceAdviceDescription
          }
        },
        [
          _c("b-form-input", {
            attrs: { type: "number", min: "0.5", step: "0.1", required: "" },
            model: {
              value: _vm.chargingPoint.price,
              callback: function($$v) {
                _vm.$set(_vm.chargingPoint, "price", $$v)
              },
              expression: "chargingPoint.price"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }