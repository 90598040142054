<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-group label="Quel est le type de prise ?">
      <b-form-select
        required
        v-model="chargingPoint.type"
        @change="onChargingPointTypeChange()"
      >
        <option
          v-for="chargingPointType in chargingPointTypes"
          :key="chargingPointType.baseType"
          :value="chargingPointType.baseType"
          >{{ chargingPointType.baseType }}</option
        >
      </b-form-select>
    </b-form-group>
    <b-form-group label="Quelle est la puissance de la prise ?">
      <b-form-select required v-model="chargingPoint.power">
        <option
          v-for="chargingPointPower in chargingPointPowers"
          :key="chargingPointPower.rowKey"
          :value="chargingPointPower.powerKVA"
          >{{ chargingPointPower.powerKVA + ' KVA' }}</option
        >
      </b-form-select>
    </b-form-group>
    <!-- Change this to b-form-checkbox or b-form-radio -->
    <b-form-group label="Qui peut voir mon point de recharge ?">
      <b-button-group class="switch">
        <b-button
          variant="dark"
          :class="{ inactive: chargingPoint.private }"
          @click="toggleVisibility"
          >Tout le monde</b-button
        >
        <b-button
          variant="dark"
          :class="{ inactive: !chargingPoint.private }"
          @click="toggleVisibility"
          >Mes invités</b-button
        >
      </b-button-group>
      <b-form-checkbox v-show="false" v-model="chargingPoint.private" />
    </b-form-group>
  </b-form>
</template>

<script>
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step3',
  props: {
    chargingPoint: { type: Object, required: true },
    chargingPointTypes: { type: Array, default: () => [] }
  },
  mixins: [StepMixin],
  computed: {
    chargingPointType() {
      return this.chargingPointTypes.find(
        t => t.baseType == this.chargingPoint.type
      )
    },
    chargingPointPowers() {
      return this.chargingPointType ? this.chargingPointType.versions : []
    }
  },
  methods: {
    toggleVisibility() {
      this.chargingPoint.private = !this.chargingPoint.private
    },
    onChargingPointTypeChange() {
      this.chargingPoint.power = this.chargingPointPowers[0].powerKVA
    }
  }
}
</script>

<style lang="scss" scoped>
#register-provider {
  .switch {
    width: 100%;
    border-radius: 10px;
    background-color: #f7f7f7;

    .btn {
      &.inactive {
        background-color: #f7f7f7;
        color: #9a9a9a;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}
</style>
