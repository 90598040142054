<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-group label="Quelle est l'adresse de votre point de recharge ?">
      <InputAddress
        v-model="addressLocation"
        @select="$emit('select', $event)"
      />
    </b-form-group>
  </b-form>
</template>

<script>
import InputAddress from '@/components/Search/InputAddress.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step2',
  components: { InputAddress },
  model: {
    prop: 'cpAddressLocation',
    event: 'select'
  },
  mixins: [StepMixin],
  props: {
    cpAddressLocation: { type: Object, required: true }
  },
  data() {
    return {
      addressLocation: {}
    }
  }
}
</script>
<style lang="scss">
form {
  position: relative;

  fieldset {
    legend {
      text-transform: uppercase;
    }
  }
}
</style>
