<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-group label="Accès au stationnement">
      <b-form-select v-model="chargingPoint.context.parkability" required>
        <option
          v-for="parkability in parkabilities"
          :key="parkability.code"
          :value="parkability.label"
          >{{ parkability.label }}</option
        >
      </b-form-select>
    </b-form-group>
    <b-form-group label="Démarrage de la recharge">
      <b-form-select v-model="chargingPoint.context.accessibility" required>
        <option
          v-for="accessibility in accessibilities"
          :key="accessibility.code"
          :value="accessibility.label"
          >{{ accessibility.label }}</option
        >
      </b-form-select>
    </b-form-group>
    <b-form-group label="Détails complémentaires">
      <b-form-input v-model="chargingPoint.context.details" />
    </b-form-group>
    <b-form-row>
      <b-col class="button">
        <div class="image-picker">
          <file-upload
            ref="fuchargingPoint"
            input-id="fuchargingPoint"
            :multiple="true"
            post-action="/post.method"
            put-action="/put.method"
            class="btn btn-dark add d-flex justify-content-center"
            v-model="chargingPointFiles"
            @input-file="inputFile"
            @input-filter="inputFilterchargingPoint"
          >
            <span class="caption align-self-center"
              >AJOUTER UNE OU PLUSIEURS PHOTOS</span
            >
          </file-upload>
        </div>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <div class="chargingPoint-photos">
          <div v-for="(photo, idx) in chargingPoint.context.photos" :key="idx">
            <button
              variant="light"
              class="delete"
              @click.prevent="removeImage(idx)"
            >
              &times;
            </button>
            <img :src="photo.url" />
          </div>
        </div>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import FileUpload from 'vue-upload-component'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-provider-step5',
  components: { FileUpload },
  mixins: [StepMixin],
  props: {
    chargingPoint: { type: Object, required: true },
    parkabilities: { type: Array, default: () => [] },
    accessibilities: { type: Array, default: () => [] }
  },
  data() {
    return {
      chargingPointFiles: []
    }
  },
  methods: {
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }
      }
    },
    inputFilterchargingPoint(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|pdf)$/i.test(newFile.name)) {
          this.$refs.alertFileUpload.showModal()

          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }

      this.chargingPoint.context.photos.push({
        file: newFile.file,
        type: newFile.type,
        url: newFile.blob
      })
    },
    removeImage(index) {
      this.chargingPointFiles.splice(index, 1)
      this.chargingPoint.context.photos.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
#register-provider {
  .form-row {
    .col.button {
      display: flex;
      justify-content: center;
      margin: 47px 0;

      .image-picker {
        .btn {
          line-height: 24px;

          &.add {
            width: 168px;
            height: 82px;
          }
        }
      }
    }
  }

  .chargingPoint-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    div {
      position: relative;
      margin-top: 12px;
      width: 150px;
      height: 92px;
      border-radius: 10px;

      .delete {
        position: absolute;
        width: 36px;
        height: 36px;
        bottom: 7px;
        right: 7px;
        background: #fff;
        border-radius: 10px;

        svg {
          width: 18px;
          height: 18px;
          margin: 0;
        }
      }

      img {
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
</style>
