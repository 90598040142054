var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validated: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Quel est le type de prise ?" } },
        [
          _c(
            "b-form-select",
            {
              attrs: { required: "" },
              on: {
                change: function($event) {
                  return _vm.onChargingPointTypeChange()
                }
              },
              model: {
                value: _vm.chargingPoint.type,
                callback: function($$v) {
                  _vm.$set(_vm.chargingPoint, "type", $$v)
                },
                expression: "chargingPoint.type"
              }
            },
            _vm._l(_vm.chargingPointTypes, function(chargingPointType) {
              return _c(
                "option",
                {
                  key: chargingPointType.baseType,
                  domProps: { value: chargingPointType.baseType }
                },
                [_vm._v(_vm._s(chargingPointType.baseType))]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Quelle est la puissance de la prise ?" } },
        [
          _c(
            "b-form-select",
            {
              attrs: { required: "" },
              model: {
                value: _vm.chargingPoint.power,
                callback: function($$v) {
                  _vm.$set(_vm.chargingPoint, "power", $$v)
                },
                expression: "chargingPoint.power"
              }
            },
            _vm._l(_vm.chargingPointPowers, function(chargingPointPower) {
              return _c(
                "option",
                {
                  key: chargingPointPower.rowKey,
                  domProps: { value: chargingPointPower.powerKVA }
                },
                [_vm._v(_vm._s(chargingPointPower.powerKVA + " KVA"))]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Qui peut voir mon point de recharge ?" } },
        [
          _c(
            "b-button-group",
            { staticClass: "switch" },
            [
              _c(
                "b-button",
                {
                  class: { inactive: _vm.chargingPoint.private },
                  attrs: { variant: "dark" },
                  on: { click: _vm.toggleVisibility }
                },
                [_vm._v("Tout le monde")]
              ),
              _c(
                "b-button",
                {
                  class: { inactive: !_vm.chargingPoint.private },
                  attrs: { variant: "dark" },
                  on: { click: _vm.toggleVisibility }
                },
                [_vm._v("Mes invités")]
              )
            ],
            1
          ),
          _c("b-form-checkbox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            model: {
              value: _vm.chargingPoint.private,
              callback: function($$v) {
                _vm.$set(_vm.chargingPoint, "private", $$v)
              },
              expression: "chargingPoint.private"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }