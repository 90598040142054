var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validated: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-group",
        {
          attrs: { label: "Quelle est l'adresse de votre point de recharge ?" }
        },
        [
          _c("InputAddress", {
            on: {
              select: function($event) {
                return _vm.$emit("select", $event)
              }
            },
            model: {
              value: _vm.addressLocation,
              callback: function($$v) {
                _vm.addressLocation = $$v
              },
              expression: "addressLocation"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }